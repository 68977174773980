div.ruby-menu1-demo-header {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    z-index: 99999
}

div.ruby-menu1-demo-header-bg div.ruby-menu1-demo-download > span {
    color: #efefef
}

div.ruby-menu1-demo-header-bg div.ruby-menu1-demo-download > span > span {
    color: #efefef
}

div.ruby-menu1-demo-description {
    position: relative;
    width: 980px;
    height: 160px;
    margin: 0 auto;
    padding: 0
}

div.ruby-menu1-demo-description img {
    max-width: 200px;
    float: left;
    margin: 50px 20px 0 0
}

span.ruby-menu1-demo-highlight {
    color: #333
}

div.ruby-menu1-demo-bg-light,div.ruby-menu1-demo-bg-dark {
    width: 980px;
    height: auto;
    position: relative;
    background: #fff;
    margin: 0 auto;
    font-family: 'Open Sans',sans-serif;
    font-size: 16px
}

div.ruby-menu1-demo-bg-dark {
    background: #efefef
}

div.ruby-menu1-demo-bg-light-c:after,div.ruby-menu1-demo-bg-dark-c:after {
    content: "";
    display: table;
    clear: both
}

div.ruby-menu1-demo-cv-1 {
    float: left;
    width: 33%
}

div.ruby-menu1-demo-cv-2 {
    float: left;
    width: 67%
}

div.ruby-menu1-demo-cv-3 {
    float: left;
    width: 48%
}

div.ruby-menu1-demo-cv-4 {
    float: left;
    width: 52%
}

div.ruby-menu1-demo-cr-1 {
    float: left;
    width: 33%
}

div.ruby-menu1-demo-cr-2 {
    float: left;
    width: 66%
}

div.ruby-menu1-demo-cs-1 {
    float: left;
    width: 33%
}

div.ruby-menu1-demo-cs-2 {
    float: left;
    width: 66%
}

div.ruby-menu1-demo-cf-1,div.ruby-menu1-demo-cf-2,div.ruby-menu1-demo-cf-3,div.ruby-menu1-demo-cf-4 {
    float: left;
    width: 25%;
    text-align: center
}

div.ruby-menu1-demo-f-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto;
    padding: 22px;
    font-size: 30px;
    background: #efefef;
    color: #aaa
}

div.ruby-menu1-demo-f-title {
    width: 100%;
    height: 30px;
    margin: 0 auto;
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px
}

div.ruby-menu1-demo-f-desc {
    font-size: 14px;
    line-height: 24px;
    color: #555;
    padding: 15px
}

div.ruby-menu1-demo-cs-1 table {
    line-height: 20px
}

div.ruby-menu1-demo-cs-1 table tr,div.ruby-menu1-demo-cs-1 table td {
    padding: 0;
    font-weight: 700
}

div.ruby-menu1-demo-cs-1 table td:last-child {
    padding-top: 5px
}

h2.ruby-menu1-demo-slogan-1,h3.ruby-menu1-demo-slogan-1 {
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    color: #b1b1b1;
    margin: 0
}

h1.ruby-menu1-demo-slogan-2,h3.ruby-menu1-demo-slogan-2 {
    font-size: 36px;
    font-weight: 700;
    text-transform: none;
    color: #222;
    margin: 0
}

p.ruby-menu1-demo-slogan-description,p.ruby-menu1-demo-slogan-description-2 {
    font-size: 16px;
    line-height: 24px;
    color: #444;
    margin: 35px 0
}

p.ruby-menu1-demo-slogan-description-2 {
    color: #666;
    font-weight: 600;
    margin: -10px 0
}

span.ruby-menu1-demo-code {
    color: #888;
    text-decoration: underline
}

td.ruby-menu1-demo-theme-td-1 {
    max-width: 30px;
    text-align: left;
    padding: 6px
}

td.ruby-menu1-demo-theme-td-2 {
    min-width: 120px;
    text-align: left;
    padding: 6px
}

td.ruby-menu1-demo-theme-td-1:hover {
    cursor: pointer
}

.ruby-menu1-demo-circle {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 4px solid #222;
    display: inline-block;
    margin: 0
}

.ruby-menu1-demo-color-1 {
    background: #1aad86
}

.ruby-menu1-demo-color-2 {
    background: #0c67c2
}

.ruby-menu1-demo-color-3 {
    background: #ce0661
}

.ruby-menu1-demo-color-4 {
    background: #ea7500
}

.ruby-menu1-demo-color-5 {
    background: #74ba27
}

.ruby-menu1-demo-color-6 {
    background: #ff3264
}

.ruby-menu1-demo-color-7 {
    background: #fad814
}

.ruby-menu1-demo-color-8 {
    background: #ccc
}

.ruby-menu1-demo-color-9 {
    background: #111;
    border-color: #ccc
}

.ruby-menu1-demo-color-10 {
    background: #036de2;
    border-color: #489efd
}

.ruby-menu1-demo-color-11 {
    background: #f85252;
    border-color: #444
}

.ruby-menu1-demo-color-12 {
    background: #ca79d0;
    border-color: #f2def3
}

.ruby-menu1-demo-color-13 {
    background: #efefef;
    border-color: #222
}

.ruby-menu1-demo-color-14 {
    background: #ccc
}

div.ruby-menu1-demo-download {
    position: absolute;
    width: auto;
    top: 55px;
    right: 0
}

div.ruby-menu1-demo-download .fa {
    font-size: 20px;
    margin-right: 10px
}

div.ruby-menu1-demo-download > span {
    font-size: 16px;
    display: block;
    margin: 10px 26px
}

div.ruby-menu1-demo-download > span > span {
    font-size: 20px;
    color: #555;
    font-weight: 700
}

button.ruby-btn {
    background: #19ad86;
    border: 2px solid #19ad86;
    color: #fff;
    border-radius: 0;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer
}

button.ruby-btn:hover {
    background: #fff;
    color: #19ad86
}

.switch {
    display: table-cell;
    vertical-align: middle;
    padding: 10px
}

.cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden
}

.cmn-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

input.cmn-toggle-round + label {
    padding: 2px;
    margin-top: -10px;
    width: 40px;
    height: 20px;
    background-color: #ddd;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px
}

input.cmn-toggle-round + label:before,input.cmn-toggle-round + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: ""
}

input.cmn-toggle-round + label:before {
    right: 1px;
    background-color: #f1f1f1;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    -webkit-transition: background .4s;
    -moz-transition: background .4s;
    -o-transition: background .4s;
    transition: background .4s
}

input.cmn-toggle-round + label:after {
    width: 18px;
    background-color: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    -webkit-box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    -moz-box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    -webkit-transition: margin .4s;
    -moz-transition: margin .4s;
    -o-transition: margin .4s;
    transition: margin .4s
}

input.cmn-toggle-round:checked + label:before,input.cmn-toggle-on + label:before {
    background-color: #8ce196
}

input.cmn-toggle-round:checked + label:after,input.cmn-toggle-on + label:after {
    margin-left: 20px
}

input.cmn-toggle-on:checked + label:before {
    background-color: #f1f1f1
}

input.cmn-toggle-on:checked + label:after {
    margin-left: 0
}

img.iphone7 {
    max-width: 350px;
    float: right;
    margin-right: 0
}

@media(min-width: 768px) and (max-width:991px) {
    body {
        overflow-x:hidden
    }

    div.ruby-menu1-demo-description {
        position: relative;
        width: 760px;
        height: 160px;
        margin: 0 auto;
        padding: 0
    }

    div.ruby-menu1-demo-description img {
        max-width: 200px;
        float: left;
        margin: 50px 20px 0 0
    }

    div.ruby-menu1-demo-bg-light,div.ruby-menu1-demo-bg-dark {
        width: 760px;
        font-size: 14px
    }

    div.ruby-menu1-demo-cs-1 {
        width: 27%
    }

    div.ruby-menu1-demo-cs-2 {
        width: 73%
    }

    td.ruby-menu1-demo-theme-td-1 {
        max-width: 27px;
        padding: 7px
    }

    td.ruby-menu1-demo-theme-td-2 {
        min-width: 100px;
        padding: 7px
    }

    .ruby-menu1-demo-circle {
        width: 22px;
        height: 22px;
        border: 3px solid #222
    }

    img.iphone7 {
        max-width: 320px;
        float: right;
        margin-right: 20px
    }
}

@media(max-width: 767px) {
    div.ruby-menu1-demo-header {
        width:100%;
        height: auto
    }

    div.ruby-menu1-demo-description {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 220px;
        padding: 10px;
        text-align: center
    }

    div.ruby-menu1-demo-description img {
        float: none;
        margin: 90px 0 0
    }

    div.ruby-menu1-demo-download {
        position: relative;
        width: auto;
        top: 25px;
        right: auto;
        left: auto
    }

    div.ruby-menu1-demo-download > span {
        font-size: 20px;
        margin: 25px auto
    }

    div.ruby-menu1-demo-download > span > span {
        font-size: 26px
    }

    button.ruby-btn {
        padding: 14px 25px;
        font-size: 16px;
        text-transform: uppercase
    }

    div.ruby-menu1-demo-bg-light.ruby-menu1-demo-switches {
        display: none
    }

    div.ruby-menu1-demo-bg-light,div.ruby-menu1-demo-bg-dark {
        width: 100%;
        font-size: 16px;
        padding: 25px;
        margin-bottom: -100px
    }

    div.ruby-menu1-demo-cv-1 {
        width: 100%
    }

    div.ruby-menu1-demo-cv-2 {
        width: 100%
    }

    div.ruby-menu1-demo-cv-3 {
        width: 100%
    }

    div.ruby-menu1-demo-cv-4 {
        width: 100%
    }

    div.ruby-menu1-demo-cr-1 {
        width: 100%
    }

    div.ruby-menu1-demo-cr-2 {
        width: 100%;
        margin-top: 40px
    }

    div.ruby-menu1-demo-cf-1,div.ruby-menu1-demo-cf-2,div.ruby-menu1-demo-cf-3,div.ruby-menu1-demo-cf-4 {
        float: left;
        width: 50%;
        text-align: center
    }

    div.ruby-menu1-demo-f-desc {
        margin-bottom: 30px
    }

    img.iphone7 {
        max-width: 300px;
        float: none;
        margin-right: 0 auto
    }
}


/* theme */

div.ruby-wrapper1,div.ruby-wrapper1.ruby-vertical {
   /* background: #f9f9f9;*/
    color: #222
}

ul.ruby-menu1 > li > a {
    color: #ffffff
}
ul.ruby-menu1 > li > a:hover {
    color: #ffffff;
}

ul.ruby-menu1 > li > a:hover,ul.ruby-menu1 > li:hover > a,ul.ruby-menu1 > li.ruby-active-menu-item > a:hover,ul.ruby-menu1 > li.ruby-active-menu-item:hover > a {
    background: #222;
    color: #000000;
}

ul.ruby-menu1 > li.ruby-active-menu-item > a {
    background: #efefef;
    color: #222
}

ul.ruby-menu1.ruby-menu1-dividers > li > a,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social > a {
    border-left: 1px solid #ddd;
    border-right: 1px solid #fff
}

ul.ruby-menu1.ruby-menu1-dividers > li:first-child > a,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social:last-child > a {
    border-left: none
}

ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li > a {
    border: none;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #fff
}

ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li:first-child > a {
    border-top: none
}

ul.ruby-menu1.ruby-menu1-dividers > li > a:hover,ul.ruby-menu1.ruby-menu1-dividers > li:hover > a {
    border-left: 1px solid #222;
    border-right: 1px solid #222
}

ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li > a:hover,ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li:hover > a {
    border-bottom: 1px solid #222
}

ul.ruby-menu1.ruby-menu1-dividers > li.ruby-active-menu-item > a {
    border-left: 1px solid #efefef;
    border-right: 1px solid #fff
}

ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li.ruby-active-menu-item > a {
    border-bottom: 1px solid #fff;
    border-left: none;
    border-right: none
}

ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li > a {
    border-left: none;
    border-right: none
}

ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li.ruby-active-menu-item > a:hover,ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li.ruby-active-menu-item:hover > a {
    border-bottom: 1px solid #222;
    border-left: none
}

ul.ruby-menu1 > li > ul,ul.ruby-menu1 > li > ul > li > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul {
    border-top: 4px solid #222
}

ul.ruby-menu1 > li > ul > li > a,ul.ruby-menu1 > li > ul > li > ul > li > a,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a {
    background: #fff;
    color: #666
}

ul.ruby-menu1 > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > a:hover,ul.ruby-menu1 > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > a:hover,ul.ruby-menu1 > li > ul > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:hover {
    background: #efefef
}

ul.ruby-menu1 > li.ruby-menu1-mega > div,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div {
    background: #fff;
    border-top: 4px solid #222
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav {
    background: #efefef
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > a {
    background: #efefef;
    color: #666
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li.ruby-active-menu-item > a {
    background: #e6e6e6
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > div {
    background: #fff
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li:hover > a {
    background: #ddd
}

ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul {
    background: #efefef;
    border-bottom: 1px solid #ddd
}

ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a {
    color: #666
}

ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div {
    background: #fff
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul li > a,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div ul > li > a {
    color: #555
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul li > a:hover,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div ul > li > a:hover {
    color: #222
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul.ruby-list-with-images li span.ruby-list-desc {
    color: #999
}

span.ruby-c-title,span.ruby-c-title a {
    color: #222
}

span.ruby-c-title a:hover,span.ruby-c-category,span.ruby-c-category a,span.ruby-c-date,span.ruby-c-date a {
    color: #444
}

span.ruby-c-category a:hover,span.ruby-c-date a:hover {
    color: #222
}

@media(min-width: 768px) {
    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:after {
        background-color:#222
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:hover {
        color: #333
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li.ruby-active-menu-item > a:after,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li:hover > a:after {
        border-color: transparent #fff transparent transparent
    }
}

@media(max-width: 767px) {
    ul.ruby-menu1 > li > a {
        background:#efefef
    }

    ul.ruby-menu1 > li > a:hover,ul.ruby-menu1 > li:hover > a,ul.ruby-menu1 > li.ruby-active-menu-item > a:hover {
        background: #ccc;
        color: #111
    }

    ul.ruby-menu1 > li.ruby-active-menu-item > a {
        background: #ccc
    }

    ul.ruby-menu1 > li > ul > li > a,ul.ruby-menu1 > li > ul > li > ul > li > a,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > a,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a {
        background: #d1d1d1
    }

    ul.ruby-menu1 > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > a:hover,ul.ruby-menu1 > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > a:hover,ul.ruby-menu1 > li > ul > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:hover {
        background: #d9d9d9;
        color: #111
    }

    ul.ruby-menu1 > li > ul > li > ul > li > a {
        background: #e5e5e5
    }

    ul.ruby-menu1 > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > a:hover {
        background: #e1e1e1
    }

    ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a {
        background: #efefef
    }

    ul.ruby-menu1 > li > ul > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:hover {
        background: #fff
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li.ruby-active-menu-item > a {
        background: #bcbcbc
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li:hover > a {
        background: #d9d9d9
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li.ruby-active-menu-item > a {
        background: #bcbcbc
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li:hover > a,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:hover,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li.ruby-active-menu-item > a {
        background: #d9d9d9
    }

    span.ruby-dropdown1-toggle:after {
        color: #fff
    }

    ul.ruby-menu1 > li > a:before,ul.ruby-menu1 > li > ul > li > a:before,ul.ruby-menu1 > li > ul > li > ul > li > a:before,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:before {
        background: #333
    }

    ul.ruby-menu1 > li > ul > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:before {
        background: #555
    }

    ul.ruby-menu1 > li > ul > li > ul > li > a:before {
        background: #777
    }

    ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:before {
        background: #999
    }

    .c-hamburger span {
        background: #222
    }

    .c-hamburger span::before,.c-hamburger span::after {
        background-color: #222
    }

    .c-hamburger--htx {
        background-color: #efefef;
        color: #222
    }

    .c-hamburger--htx.is-active {
        background-color: #ddd
    }

    ul.ruby-menu1 > li.ruby-menu1-mega > div,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div {
        border-top: none
    }

    ul.ruby-menu1 > li > ul,ul.ruby-menu1 > li > ul > li > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul {
        border-top: none
    }

    ul.ruby-menu1.ruby-menu1-dividers > li,ul.ruby-menu1.ruby-menu1-dividers > li > a,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social > a,ul.ruby-menu1.ruby-menu1-dividers > li > a:hover,ul.ruby-menu1.ruby-menu1-dividers > li:hover > a {
        border: none
    }
}

/* transision */

span.ruby-dropdown1-toggle {
    transition: transform .3s ease
}

span.ruby-dropdown1-toggle:after {
    transition: transform .3s ease
}

@media(min-width: 767px) {
    ul.ruby-menu1 > li > a:after {
        transition:all .3s ease
    }

    ul.ruby-menu1 > li > ul > li > a:after,ul.ruby-menu1 > li > ul > li > ul > li > a:after,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:after {
        transition: all .3s ease
    }

    ul.ruby-menu1 > li > ul,ul.ruby-menu1 > li > div {
        transform: translateY(25px);
        transition: all .3s ease
    }

    ul.ruby-menu1 > li:hover > ul,ul.ruby-menu1 > li:hover > div {
        transform: translateY(0)
    }

    ul.ruby-menu1.ruby-vertical > li > ul,ul.ruby-menu1.ruby-vertical > li > div {
        transform: translateX(25px);
        transition: all .3s ease
    }

    ul.ruby-menu1.ruby-vertical > li:hover > ul,ul.ruby-menu1.ruby-vertical > li:hover > div {
        transform: translateX(0)
    }

    ul.ruby-menu1 > li > ul > li > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > ul {
        transform: translateX(25px);
        transition: all .3s ease
    }

    ul.ruby-menu1 > li > ul > li:hover > ul,ul.ruby-menu1 > li > ul > li > ul > li:hover > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul > li:hover > ul {
        transform: translateX(0)
    }

    ul.ruby-menu1 > li > ul > li.ruby-open-to-left > ul,ul.ruby-menu1 > li > ul > li > ul > li.ruby-open-to-left > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul > li.ruby-open-to-left > ul {
        transform: translateX(-25px);
        transition: all .3s ease
    }

    ul.ruby-menu1 > li > ul > li.ruby-open-to-left:hover > ul,ul.ruby-menu1 > li > ul > li > ul > li.ruby-open-to-left:hover > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul > li.ruby-open-to-left:hover > ul {
        transform: translateX(0)
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > div {
        transform: translateY(-50px);
        transition: all .5s ease
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog:hover > div > ul.ruby-menu1-mega-blog-nav > li.ruby-active-menu-item > div,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li:hover > div {
        transform: translateY(0)
    }

    ul.ruby-menu1.ruby-vertical > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > div {
        transform: translateX(50px);
        transition: all .5s ease
    }

    ul.ruby-menu1.ruby-vertical > li.ruby-menu1-mega-blog:hover > div > ul.ruby-menu1-mega-blog-nav > li.ruby-active-menu-item > div,ul.ruby-menu1.ruby-vertical > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li:hover > div {
        transform: translateX(0)
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div {
        transform: translateY(-50px);
        transition: all .5s ease
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop:hover > div > ul > li.ruby-active-menu-item > div,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li:hover > div {
        transform: translateY(0)
    }

    ul.ruby-menu1.ruby-vertical > li.ruby-menu1-mega-shop > div > ul > li > div {
        transform: translateX(50px);
        transition: all .5s ease
    }

    ul.ruby-menu1.ruby-vertical > li.ruby-menu1-mega-shop:hover > div > ul > li.ruby-active-menu-item > div,ul.ruby-menu1.ruby-vertical > li.ruby-menu1-mega-shop > div > ul > li:hover > div {
        transform: translateX(0)
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:after {
        -webkit-transition: all .4s ease;
        transition: all .4s ease
    }
}

/* main */

*,:before,:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

body {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
}

.ruby-wrapper1 {
    position: relative;
}

.ruby-wrapper1 ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.ruby-wrapper1 > ul {
    position: relative;
    height: 50px;
    margin: 0 auto;
    background: #1370b5;
}

ul.ruby-menu1 > li {
    float: left;
    font-size: 14px
}

ul.ruby-menu1 > li.ruby-menu1-right {
    float: right
}

ul.ruby-menu1 > li > a {
    display: block;
    line-height: 50px;
    padding: 0 10px 0 0;
    text-decoration: none;
    font-family: 'Open Sans',sans-serif;
    font-weight:400;
    text-transform: uppercase
}

ul.ruby-menu1 > li > a:only-child {
    padding: 0 15px
}

ul.ruby-menu1 > li > a:hover,ul.ruby-menu1 > li:hover > a,ul.ruby-menu1 > li.ruby-active-menu-item > a:hover,ul.ruby-menu1 > li.ruby-active-menu-item:hover > a {
    background: #1370b5;
    color: #ffffff;
}

ul.ruby-menu1 > li > a:only-child:after,ul.ruby-menu1 > li:hover > a:only-child:after {
    content: ''
}

ul.ruby-menu1 > li.ruby-active-menu-item > a {
    background: #17a47d
}

ul.ruby-menu1 > li > a .fa {
    position: relative;
    width: 24px
}

ul.ruby-menu1 > li > a > img {
    max-width: 16px;
    display: block
}

ul.ruby-menu1 > li.ruby-menu1-social > a {
    padding: 0;
    width: 30px;
    padding: 0 8px;
    font-size: 16px
}

ul.ruby-menu1 > li.ruby-menu1-social > a > span {
    display: none
}

ul.ruby-menu1 > li.ruby-menu1-social.ruby-menu1-search > a,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social.ruby-menu1-search > a {
    transition: all .5s ease;
    max-height: 50px;
    width: 30px;
    transition: all .5s ease;
    transition-delay: .3s
}

ul.ruby-menu1 > li.ruby-menu1-social.ruby-menu1-search > a:hover,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social.ruby-menu1-search > a:hover {
    width: 120px
}

ul.ruby-menu1 > li.ruby-menu1-social.ruby-menu1-search > a:hover > span input,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social.ruby-menu1-search > a:hover > span input {
    transform: scaleY(1);
    max-width: 80px
}

ul.ruby-menu1 > li.ruby-menu1-social.ruby-menu1-search > a span,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social.ruby-menu1-search > a span {
    display: inline
}

ul.ruby-menu1 > li.ruby-menu1-social.ruby-menu1-search > a > span input,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social.ruby-menu1-search > a > span input {
    position: absolute;
    width: 80px;
    height: 50px;
    line-height: 50px;
    margin: 0;
    background: none;
    color: #efefef;
    border: none;
    font-family: 'Open Sans',sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 0;
    transform: scaleY(0);
    transition: all .5s ease;
    transition-delay: .3s
}

ul.ruby-menu1 > li.ruby-menu1-social.ruby-menu1-search > a > span input:focus {
    outline: none;
    color: #efefef
}

ul.ruby-menu1 > li.ruby-menu1-social.ruby-menu1-search > a > span input::-webkit-input-placeholder {
    color: #efefef
}

ul.ruby-menu1.ruby-menu1-dividers > li > a,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social > a {
    border-left: 1px solid #111;
    border-right: 1px solid #666
}

ul.ruby-menu1.ruby-menu1-dividers > li:first-child > a,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social:last-child > a {
    border-left: none
}

ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li > a {
    border: none;
    border-top: 1px solid #111;
    border-bottom: 1px solid #666
}

ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li:first-child > a {
    border-top: none
}

ul.ruby-menu1.ruby-menu1-dividers > li > a:hover,ul.ruby-menu1.ruby-menu1-dividers > li:hover > a {
    border-right: 1px solid #19ad86
}

ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li > a:hover,ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li:hover > a {
    border-bottom: 1px solid #19ad86
}

ul.ruby-menu1.ruby-menu1-dividers > li.ruby-active-menu-item > a {
    border-right: 1px solid #17a47d
}

ul.ruby-menu1.ruby-vertical.ruby-menu1-dividers > li.ruby-active-menu-item > a {
    border-bottom: 1px solid #17a47d
}

ul.ruby-menu1 > li > ul,ul.ruby-menu1 > li > ul > li > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul {
    position: absolute;
    width: 125%;
    height: auto;
    top: 100%;
    margin-top: 12%;
    left: -15px;
    background-color: #fff;
    border-radius: 8px;
    border-color: #1370b5;
    padding: 15px 15px;
    opacity: 0;
    z-index: 1;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -ms-box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 7px 10px -1px rgb(0 0 0 / 20%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ffffff;
    content: "";
      opacity: 1;
  visibility: visible;
  -webkit-transform: translate(0px);
  transform: translate(0px); 
}

ul.ruby-menu1 > li > ul > li,ul.ruby-menu1 > li > ul > li > ul > li {
    position: relative
}

ul.ruby-menu1 > li > ul > li > a,ul.ruby-menu1 > li > ul > li > ul > li > a,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a {
   text-decoration: none;
    font-family: 'Open Sans',sans-serif;
    display: inline-block;
    position: relative;
    padding: 5px 5px;
    z-index: 1;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #363636;
    text-transform: capitalize;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 100%;
}

ul.ruby-menu1 > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > a:hover,ul.ruby-menu1 > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > a:hover,ul.ruby-menu1 > li > ul > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:hover {
    background: #efefef
}

ul.ruby-menu1 > li > ul > li > a:only-child:after,ul.ruby-menu1 > li > ul > li > ul > li > a:only-child:after,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:only-child:after {
    content: ""
}

ul.ruby-menu1 > li > ul > li > a .fa,ul.ruby-menu1 > li > ul > li > ul > li > a .fa,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a .fa {
    position: relative;
    width: 24px
}

ul.ruby-menu1 > li.ruby-menu1-right > ul {
    right: 0
}

ul.ruby-menu1 > li > ul > li > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul {
    left: 170px;
    top: -4px;
    z-index: 100
}

ul.ruby-menu1 > li > ul > li.ruby-open-to-left > ul,ul.ruby-menu1 > li > ul > li > ul > li.ruby-open-to-left > ul {
    left: auto;
    right: 170px
}

ul.ruby-menu1 > li.ruby-menu1-mega > div,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div {
    position: absolute;
    width: 100%;
    height: auto;
    top: 100%;
    margin-top: 10%;
    left: -15px;
    background-color: #fff;
    border-radius: 8px;
    border-color: #1370b5;
    padding: 10px 0;
    opacity: 0;
    z-index: 1;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -ms-box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 7px 10px -1px rgb(0 0 0 / 20%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ffffff;
    content: "";
      opacity: 1;
  visibility: visible;
  -webkit-transform: translate(0px);
  transform: translate(0px);
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav {
    position: relative;
    width: 200px;
    height: 100%;
    left: 0;
    top: 0;
    background: #efefef
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li {
    font-size: 14px
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > a {
    display: block;
    line-height: 40px;
    padding: 0 15px;
    background: #efefef;
    color: #666;
    text-decoration: none
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li.ruby-active-menu-item > a {
    background: #e6e6e6
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > div {
    position: absolute;
    width: 780px;
    height: auto;
    min-height: 100%;
    left: 200px;
    top: 0;
    background: #fff
}

ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li:hover > a {
    background: #ddd
}

ul.ruby-menu1 > li.ruby-menu1-mega-shop > div {
    text-align: center;
    z-index: 999999
}

ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul {
    width: 100%;
    background: #efefef;
    margin: 0 auto;
    padding: 0;
    border-bottom: 1px solid #ddd;
    font-size: 0;
    z-index: 100
}

ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li {
    display: inline-block;
    margin: 0
}

ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a {
    display: block;
    padding: 20px 15px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    font-stretch: expanded;
    color: #666
}

ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li:hover > a,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:hover,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li.ruby-active-menu-item > a {
    text-decoration: none
}

ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a {
    position: relative;
    text-decoration: none;
    display: inline-block
}

ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div {
    position: absolute;
    width: 100%;
    left: 0;
    top: auto;
    margin-top: 1px;
    text-align: left;
    background: #fff;
    z-index: -1
}

div.ruby-grid1,div.ruby-grid1-lined {
    position: relative;
    width: 70%;
    padding: 0;
    margin: 0
}

div.ruby-grid1 > div.ruby-row1 {
    position: relative;
    width: 100%;
    height: auto
}

div.ruby-grid1 > div.ruby-row1 div.ruby-row1 [class^="ruby-col"] {
    margin-top: 16px
}

div.ruby-grid1 > div.ruby-row1 div.ruby-row1:first-of-type [class^="ruby-col"] {
    margin-top: 0
}

div.ruby-grid1.ruby-grid1-lined > div.ruby-row1:after {
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background: -webkit-linear-gradient(left,rgba(204,204,204,0) 0%,rgba(204,204,204,0.48) 24%,rgba(204,204,204,1) 50%,rgba(204,204,204,0.42) 79%,rgba(204,204,204,0) 100%)
}

div.ruby-grid1.ruby-grid1-lined > div.ruby-row1:last-child:after {
    height: 0
}

div.ruby-grid1 > div.ruby-row1::after {
    content: "";
    display: table;
    clear: both
}

div.ruby-grid1 > div.ruby-row1 [class^="ruby-col"] {
    position: relative;
    float: left;
    height: auto
}

div.ruby-grid1 > div.ruby-row1 > [class^="ruby-col"] {
    padding: 16px
}

div.ruby-grid1 > div.ruby-row1 > [class^="ruby-col"] [class^="ruby-col"] {
    padding-left: 16px
}

div.ruby-grid1 > div.ruby-row1 > [class^="ruby-col"] [class^="ruby-col"]:first-of-type {
    padding-left: 0
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-1 {
    width: 8.33%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-2 {
    width: 16.66%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-3 {
    width: 25%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-4 {
    width: 33.33%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-5 {
    width: 41.66%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-6 {
    width: 50%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-7 {
    width: 58.33%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-8 {
    width: 66.66%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-9 {
    width: 75%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-10 {
    width: 83.33%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-11 {
    width: 91.66%
}

div.ruby-grid1 > div.ruby-row1 div.ruby-col-12 {
    width: 100%
}

div.ruby-grid1.ruby-grid1-lined > div.ruby-row1 > div[class^="ruby-col"]:after {
    content: "";
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    right: 0;
    background: -webkit-linear-gradient(top,rgba(204,204,204,0) 0%,rgba(204,204,204,0.48) 24%,rgba(204,204,204,1) 50%,rgba(204,204,204,0.42) 79%,rgba(204,204,204,0) 100%)
}

div.ruby-grid1.ruby-grid1-lined > div.ruby-row1 > div[class^="ruby-col"]:last-child:after {
    width: 0
}

div.ruby-grid1 img {
    position: relative;
    max-width: 100%;
    height: auto;
    display: block
}

h3.ruby-list-heading {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    margin: 0
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div ul {
    margin: 0;
    margin-top: 10px
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul li,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div ul > li {
    position: relative;
    font-size: 14px;
    line-height: 28px;
    display: block;
    width: 100%
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul li > a,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div ul > li > a {
    text-decoration: none;
    font-family: 'Open Sans',sans-serif;
    display: inline-block;
    position: relative;
    padding: 5px 5px;
    z-index: 1;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #363636;
    text-transform: capitalize;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 100%;
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul li > a:hover,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div ul > li > a:hover {
    color: #222;
    text-decoration: underline
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul li .fa,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div ul > li .fa {
    position: relative;
    width: 24px
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul.ruby-list-with-images li {
    height: 50px
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul.ruby-list-with-images li span.ruby-list-desc {
    font-size: 11px;
    display: block;
    position: absolute;
    top: 16px;
    left: 72px;
    color: #999
}

ul.ruby-menu1 > li.ruby-menu1-mega > div ul li > a > img {
    float: left;
    width: 60px;
    margin-right: 12px
}

span.ruby-c-title,span.ruby-c-category,span.ruby-c-date {
    position: relative;
    display: block
}

span.ruby-c-title a,span.ruby-c-category a,span.ruby-c-date a {
    text-decoration: none
}

span.ruby-c-title,span.ruby-c-title a {
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 20px;
    margin-top: -5px;
    color: #222
}

span.ruby-c-title a:hover {
    color: #444;
    text-decoration: underline
}

span.ruby-c-category,span.ruby-c-category a {
    font-size: 11px;
    margin: 9px 0;
    color: #444
}

span.ruby-c-date,span.ruby-c-date a {
    font-size: 11px;
    color: #444
}

span.ruby-c-category a:hover,span.ruby-c-date a:hover {
    color: #19ad86
}

div.ruby-c-inline {
    display: block;
    position: relative;
    height: 14px;
    margin: 7px 0
}

div.ruby-c-inline span.ruby-c-category,div.ruby-c-inline span.ruby-c-date {
    position: absolute;
    display: inline-block
}

div.ruby-c-inline span.ruby-c-date {
    right: 0
}

div.ruby-c-inline span.ruby-c-category,div.ruby-c-inline span.ruby-c-category a {
    margin: 0
}

span.ruby-c-content {
    display: block;
    position: relative;
    top: 7px;
    font-size: 12px;
    line-height: 18px;
    text-align: justify
}

.ruby-wrapper1 .visible-xs {
    visibility: hidden;
    position: absolute
}

@media(min-width: 768px) {
    div.ruby-wrapper1.ruby-menu1-transparent {
        background:none
    }

    div.ruby-wrapper1.ruby-menu1-transparent > ul.ruby-menu1 > li.ruby-active-menu-item > a {
        background: none
    }

    div.ruby-wrapper1.ruby-menu1-transparent > ul.ruby-menu1 > li.ruby-menu1-social > a {
        border: none
    }

    div.ruby-wrapper1.ruby-menu1-transparent > ul.ruby-menu1.ruby-menu1-dividers > li > a {
        border: none
    }

    div.ruby-wrapper1.ruby-menu1-transparent > ul.ruby-menu1 > li:hover > a,div.ruby-wrapper1.ruby-menu1-transparent > ul.ruby-menu1 > li > a:hover,div.ruby-wrapper1.ruby-menu1-transparent > ul.ruby-menu1.ruby-menu1-dividers > li:hover > a,div.ruby-wrapper1.ruby-menu1-transparent > ul.ruby-menu1.ruby-menu1-dividers > li > a:hover {
        background: none;
        color: #e2e2e2
    }

    div.ruby-wrapper1.ruby-menu1-transparent > ul.ruby-menu1 > li.ruby-menu1-social.ruby-menu1-search > a:hover,div.ruby-wrapper1.ruby-menu1-transparent > ul.ruby-menu1 > li.ruby-menu1-social.ruby-menu1-search:hover > a {
        background: none
    }

    div.ruby-wrapper1.ruby-menu1-full-width {
        width: 100%
    }

    div.ruby-wrapper1.ruby-menu1-full-width > ul.ruby-menu1 {
        width: 980px;
        margin: 0 auto
    }

    ul.ruby-menu1 > li > ul,ul.ruby-menu1 > li > ul > li > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul {
        visibility: hidden;
        opacity: 0
    }

    ul.ruby-menu1 > li:hover > ul,ul.ruby-menu1 > li > ul > li:hover > ul,ul.ruby-menu1 > li > ul > li > ul > li:hover > ul {
        visibility: visible;
        opacity: 1
    }

    ul.ruby-menu1 > li.ruby-menu1-mega > div,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div {
        visibility: hidden;
        opacity: 0
    }

    ul.ruby-menu1 > li.ruby-menu1-mega:hover > div,ul.ruby-menu1 > li.ruby-menu1-mega-blog:hover > div,ul.ruby-menu1 > li.ruby-menu1-mega-shop:hover > div {
        visibility: visible;
        opacity: 1;
        width: 780px;
        top: 120%;
        font-family: "Font Awesome 5 Free";
        content: "\f105";
        display: block;
        color: inherit;
        line-height: 20px;
        font-size: 16px;
        font-weight: 900;
        z-index: 5;
    }
    ul.ruby-menu1 > li > a:hover {
    background-color: #ddd;
    width: auto;
}

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > div {
        visibility: hidden;
        opacity: 0
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog:hover > div > ul.ruby-menu1-mega-blog-nav > li.ruby-active-menu-item > div {
        visibility: visible;
        opacity: 1
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li:hover > div {
        visibility: visible;
        opacity: 1;
        z-index: 101
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div {
        visibility: hidden;
        opacity: 0
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop:hover > div > ul > li.ruby-active-menu-item > div,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li:hover > div {
        visibility: visible;
        opacity: 1
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:after {
        height: 1px;
        position: absolute;
        content: '';
        background-color: #19ad86;
        width: 0
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:before {
        top: 10px;
        left: 5%
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:after {
        bottom: 10px;
        right: 5%
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:hover,.snip1217 .current a {
        color: #333
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:hover:before,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li.ruby-active-menu-item > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:hover:after,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li.ruby-active-menu-item > a:after,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li:hover > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li:hover > a:after {
        width: 90%
    }

    ul.ruby-menu1 > li > a:after {
            position: absolute;
    right: -2px;
    top: 50%;
    margin-top: -10px;
    font-family: "Font Awesome 5 Free";
    content: "\f0d7";
    display: block;
    line-height: 20px;
    font-size: 12px;
    font-weight: 900;
    color: #ffffff;
    z-index: 5;
    }

    ul.ruby-menu1 > li:hover > a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg)
    }

    ul.ruby-menu1 > li > ul > li > a:after,ul.ruby-menu1 > li > ul > li > ul > li > a:after,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:after {
        font-family: "FontAwesome";
        text-decoration: none;
        font-size: 10px;
        padding: 0;
        content: '\f105';
        position: absolute;
        right: 15px
    }

    ul.ruby-menu1 > li > ul > li:hover > a:after,ul.ruby-menu1 > li > ul > li > ul > li:hover > a:after,ul.ruby-menu1 > li > ul > li > ul > li > ul > li:hover > a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg)
    }

    ul.ruby-menu1 > li > ul > li.ruby-open-to-left > a:after,ul.ruby-menu1 > li > ul > li > ul > li.ruby-open-to-left > a:after,ul.ruby-menu1 > li > ul > li > ul > li > ul > li.ruby-open-to-left > a:after {
        content: '\f104';
        right: auto;
        left: 10px
    }

    ul.ruby-menu1 > li > ul > li.ruby-open-to-left:hover > a:after,ul.ruby-menu1 > li > ul > li > ul > li.ruby-open-to-left:hover > a:after,ul.ruby-menu1 > li > ul > li > ul > li > ul > li.ruby-open-to-left:hover > a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg)
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li.ruby-active-menu-item > a:after,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li:hover > a:after {
        content: "";
        position: absolute;
        right: 0;
        margin-top: 13px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 8px 6px 0;
        border-color: transparent #fff transparent transparent
    }
}

/* responsive */

@media(max-width: 767px) {
    div.ruby-wrapper1 .hidden-xs {
        visibility:hidden;
        display: none
    }

    div.ruby-wrapper1 {
        position: absolute;
        width: 100%;
        top: 0;
        background: #111
    }

    .ruby-wrapper1 .visible-xs {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0
    }

    div.ruby-wrapper1 > ul {
        position: absolute;
        top: 50px;
        width: 100%
    }

    ul.ruby-menu1 > li,ul.ruby-menu1 > li.ruby-menu1-right {
        float: none
    }

    ul.ruby-menu1 > li > a {
        padding: 0 65px;
        background: #19ad86
    }

    ul.ruby-menu1 > li > a:only-child {
        padding: 0 65px
    }

    ul.ruby-menu1 > li > a:hover,ul.ruby-menu1 > li:hover > a,ul.ruby-menu1 > li.ruby-active-menu-item > a:hover {
        background: #127e61
    }

    ul.ruby-menu1 > li.ruby-active-menu-item > a {
        background: #159572
    }

    ul.ruby-menu1 > li.ruby-menu1-social > a > span {
        display: block
    }

    ul.ruby-menu1 > li > ul,ul.ruby-menu1 > li > ul > li > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul {
        position: relative;
        width: 100%;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-top: none
    }

    ul.ruby-menu1 > li,ul.ruby-menu1 > li > ul > li,ul.ruby-menu1 > li > ul > li > ul > li,ul.ruby-menu1 > li > ul > li > ul > li > ul > li {
        position: relative
    }

    ul.ruby-menu1 > li > ul > li > a,ul.ruby-menu1 > li > ul > li > ul > li > a,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a {
        width: 100%;
        line-height: 50px;
        padding: 0 80px;
        background: #d1d1d1
    }

    ul.ruby-menu1 > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > a:hover,ul.ruby-menu1 > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > a:hover,ul.ruby-menu1 > li > ul > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:hover {
        background: #d9d9d9;
        color: #111
    }

    ul.ruby-menu1 > li > ul > li > ul,ul.ruby-menu1 > li > ul > li > ul > li > ul {
        left: auto;
        top: auto;
        z-index: auto
    }

    ul.ruby-menu1 > li > ul > li.ruby-open-to-left > ul,ul.ruby-menu1 > li > ul > li > ul > li.ruby-open-to-left > ul {
        left: auto;
        right: auto
    }

    ul.ruby-menu1 > li > ul > li > ul > li > a {
        background: #e5e5e5;
        padding: 0 95px
    }

    ul.ruby-menu1 > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > a:hover {
        background: #e1e1e1
    }

    ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a {
        background: #efefef;
        padding: 0 110px
    }

    ul.ruby-menu1 > li > ul > li > ul > li > ul > li:hover > a,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:hover {
        background: #fff
    }

    ul.ruby-menu1 > li.ruby-menu1-social > a {
        border: none
    }

    ul.ruby-menu1 > li.ruby-menu1-mega,ul.ruby-menu1 > li.ruby-menu1-mega-blog,ul.ruby-menu1 > li.ruby-menu1-mega-shop {
        position: relative
    }

    ul.ruby-menu1 > li.ruby-menu1-mega > div,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div {
        position: relative;
        top: auto;
        left: auto;
        border-top: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav {
        width: 100%;
        height: auto;
        left: auto;
        top: auto
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li {
        position: relative
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > a {
        line-height: 50px;
        padding: 0 75px;
        background: #d1d1d1;
        color: #222
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li.ruby-active-menu-item > a {
        background: #bcbcbc
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > div {
        position: relative;
        width: 100%;
        min-height: auto;
        left: auto;
        top: auto;
        z-index: auto
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li:hover > a {
        background: #d9d9d9
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div {
        position: relative;
        text-align: left
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul {
        position: relative;
        border: none;
        font-size: 10px;
        margin-bottom: -1px
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li {
        position: relative;
        display: block;
        margin-top: -1px
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a {
        padding: 0 80px;
        line-height: 50px;
        max-height: 50px;
        text-transform: none;
        font-weight: 400;
        background: #d1d1d1;
        color: #222;
        border: none
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li.ruby-active-menu-item > a {
        background: #bcbcbc
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li:hover > a,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:hover,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li.ruby-active-menu-item > a {
        background: #d9d9d9
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a {
        display: block
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div {
        position: relative;
        top: 0;
        z-index: 9999;
        margin: 0
    }

    ul.ruby-menu1 > li.ruby-menu1-social img {
        display: inline-table;
        position: absolute;
        top: 15px
    }

    ul.ruby-menu1 > li.ruby-menu1-social > a {
        width: 100%
    }

    ul.ruby-menu1 > li.ruby-menu1-social .fa {
        position: absolute;
        top: 17px
    }

    ul.ruby-menu1 > li.ruby-menu1-social span {
        line-height: 50px;
        padding: 0 25px
    }

    span.ruby-dropdown1-toggle {
       

        position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -10px;
 font-family: 'Open Sans',sans-serif;
  content: "\f105";
  display: block;
  color: inherit;
  line-height: 20px;
  font-size: 10px;
  font-weight: 500;
  z-index: 5;
    }

    span.ruby-dropdown1-toggle:after {
        font-family: "FontAwesome";
        text-decoration: none;
        content: '\f107';
        color: #fff;
        font-size: 15px;
        right: 0
    }

    span.ruby-dropdown1-toggle.ruby-dropdown1-toggle-rotate {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    ul.ruby-menu1 > li > a:before,ul.ruby-menu1 > li > ul > li > a:before,ul.ruby-menu1 > li > ul > li > ul > li > a:before,ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:before {
        content: "";
        width: 50px;
        height: 50px;
        background: #333;
        position: absolute;
        left: 0;
        top: 0
    }

    ul.ruby-menu1 > li > ul > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > a:before,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a:before {
        background: #555
    }

    ul.ruby-menu1 > li > ul > li > ul > li > a:before {
        background: #777
    }

    ul.ruby-menu1 > li > ul > li > ul > li > ul > li > a:before {
        background: #999
    }

    ul.ruby-menu1 > li > ul > li > ul > li > ul,ul.ruby-menu1 > li > ul > li > ul,ul.ruby-menu1 > li > ul,ul.ruby-menu1,ul.ruby-menu1 > li.ruby-menu1-mega > div,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > div,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div {
        max-height: 0;
        transform: scaleY(0);
        overflow: hidden
    }

    ul.ruby-menu1 > li > ul.ruby-mobile-sublevel-show,ul.ruby-menu1 > li > ul > li > ul.ruby-mobile-sublevel-show,ul.ruby-menu1 > li > ul > li > ul > li > ul.ruby-mobile-sublevel-show,ul.ruby-menu1.ruby-mobile-sublevel-show,ul.ruby-menu1 > li.ruby-menu1-mega > div.ruby-mobile-sublevel-show,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div.ruby-mobile-sublevel-show,ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > div.ruby-mobile-sublevel-show,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div.ruby-mobile-sublevel-show,ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > div.ruby-mobile-sublevel-show {
        max-height: 20000px;
        transform: scaleY(1)

    }

    div.ruby-grid1 > div.ruby-row1 div.ruby-row1 [class^="ruby-col"] {
        margin-top: 0
    }

    div.ruby-grid1 > div.ruby-row1 div.ruby-row1:first-of-type [class^="ruby-col"] {
        margin-top: 0
    }

    div.ruby-grid1.ruby-grid1-lined > div.ruby-row1:after {
        height: 0
    }

    div.ruby-grid1 > div.ruby-row1 > [class^="ruby-col"] {
        padding: 0
    }

    div.ruby-grid1 > div.ruby-row1 > [class^="ruby-col"] [class^="ruby-col"] {
        padding-left: 0
    }

    div.ruby-grid1 > div.ruby-row1 > [class^="ruby-col"] [class^="ruby-col"]:first-of-type {
        padding-left: 0
    }

    div.ruby-grid1 > div.ruby-row1 div.ruby-col-1,div.ruby-grid1 > div.ruby-row1 div.ruby-col-2,div.ruby-grid1 > div.ruby-row1 div.ruby-col-3,div.ruby-grid1 > div.ruby-row1 div.ruby-col-4,div.ruby-grid1 > div.ruby-row1 div.ruby-col-5,div.ruby-grid1 > div.ruby-row1 div.ruby-col-6,div.ruby-grid1 > div.ruby-row1 div.ruby-col-7,div.ruby-grid1 > div.ruby-row1 div.ruby-col-8,div.ruby-grid1 > div.ruby-row1 div.ruby-col-9,div.ruby-grid1 > div.ruby-row1 div.ruby-col-10,div.ruby-grid1 > div.ruby-row1 div.ruby-col-11,div.ruby-grid1 > div.ruby-row1 div.ruby-col-12 {
        width: 100%;
        padding: 20px 30px
    }

    div.ruby-grid1.ruby-grid1-lined > div.ruby-row1 > div[class^="ruby-col"]:after {
        width: 0
    }

    div.ruby-grid1 img {
        position: relative;
        max-width: 100%;
        height: auto;
        display: block
    }

    div.ruby-grid1 > div.ruby-row1::after {
        content: "";
        display: table;
        clear: both
    }

    .c-hamburger {
        display: block;
        position: relative;
        overflow: hidden;
        margin: 0;
        padding: 0;
        width: 50px;
        height: 50px;
        font-size: 0;
        text-indent: -9999px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none;
        border-radius: none;
        border: none;
        cursor: pointer;
        -webkit-transition: background .3s;
        transition: background .3s
    }

    .c-hamburger:focus {
        outline: none
    }

    .c-hamburger span {
        display: block;
        position: absolute;
        top: 24px;
        left: 8px;
        right: 8px;
        height: 3px;
        background: #fff
    }

    .c-hamburger span::before,.c-hamburger span::after {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #fff;
        content: ""
    }

    .c-hamburger span::before {
        top: -10px
    }

    .c-hamburger span::after {
        bottom: -10px
    }

    .c-hamburger--htx {
        background-color: #19ad86
    }

    .c-hamburger--htx span {
        -webkit-transition: background 0 .3s;
        transition: background 0 .3s
    }

    .c-hamburger--htx span::before,.c-hamburger--htx span::after {
        -webkit-transition-duration: 0.3s,.3s;
        transition-duration: 0.3s,.3s;
        -webkit-transition-delay: 0.3s,0;
        transition-delay: 0.3s,0
    }

    .c-hamburger--htx span::before {
        -webkit-transition-property: top,-webkit-transform;
        transition-property: top,transform
    }

    .c-hamburger--htx span::after {
        -webkit-transition-property: bottom,-webkit-transform;
        transition-property: bottom,transform
    }

    .c-hamburger--htx.is-active {
        background-color: #333
    }

    .c-hamburger--htx.is-active span {
        background: none
    }

    .c-hamburger--htx.is-active span::before {
        top: 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg)
    }

    .c-hamburger--htx.is-active span::after {
        bottom: 0;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }

    .c-hamburger--htx.is-active span::before,.c-hamburger--htx.is-active span::after {
        -webkit-transition-delay: 0s,.3s;
        transition-delay: 0s,.3s
    }

    ul.ruby-menu1.ruby-menu1-dividers > li,ul.ruby-menu1.ruby-menu1-dividers > li > a,ul.ruby-menu1.ruby-menu1-dividers > li.ruby-menu1-social > a,ul.ruby-menu1.ruby-menu1-dividers > li > a:hover,ul.ruby-menu1.ruby-menu1-dividers > li:hover > a {
        border: none
    }

    div.ruby-wrapper1.ruby-menu1-transparent > ul.ruby-menu1 > li.ruby-active-menu-item > a {
        padding-left: 45px
    }

    ul.ruby-menu1 > li.ruby-menu1-social.ruby-menu1-search > a {
        display: none
    }
}
   .vl {
  border-left: 1px solid lightgrey;
  height: 500px;
}

@media(min-width: 768px) and (max-width:991px) {
    .ruby-wrapper1 .hidden-md1 {
        visibility:hidden;
        display: none
    }


    div.ruby-wrapper1 {
        width: 760px
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav {
        width: 170px
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > div {
        width: 590px;
        left: 170px
    }

    ul.ruby-menu1.ruby-vertical > li.ruby-menu1-mega > div,ul.ruby-menu1.ruby-vertical > li.ruby-menu1-mega-blog > div,ul.ruby-menu1.ruby-vertical > li.ruby-menu1-mega-shop > div {
        width: 560px
    }

    ul.ruby-menu1.ruby-vertical > li.ruby-menu1-mega-blog > div > ul.ruby-menu1-mega-blog-nav > li > div {
        width: 360px
    }

    span.ruby-c-date {
        display: none;
        visibility: hidden;
        opacity: 0
    }

    div.ruby-grid1 > div.ruby-row1 div.ruby-col-3-md {
        width: 25%
    }

    div.ruby-grid1 > div.ruby-row1 div.ruby-col-4-md {
        width: 33.33%
    }

    div.ruby-grid1 > div.ruby-row1 div.ruby-col-6-md {
        width: 50%
    }

    div.ruby-grid1 > div.ruby-row1 div.ruby-col-12-md {
        width: 100%
    }

    div.ruby-grid1.ruby-grid1-lined > div.ruby-row1:after,div.ruby-grid1.ruby-grid1-lined > div.ruby-row1 > div[class^="ruby-col"]:after {
        background: none
    }

    ul.ruby-menu1 > li.ruby-menu1-mega-shop > div > ul > li > a {
        padding: 20px 5px;
        font-size: 12px;
        font-stretch: condensed;
        color: #666
    }
}
